.experiments-toolbar {
    justify-content: left;
    font-weight: bold;
}

/*No idea why we had this, will keep this for a while in comment as a reminder just in case*/
/*.experiments-list {*/
/*    width: calc(100% - 240px);*/
/*}*/

.hidden {
    visibility: hidden;
}

:root {
    --color-single: hsl(223, 100%, 69.6%);
    --color-single--default-bg: hsl(223, 100%, 79.6%);
    --color-single--bg: hsl(223, 100%, 94.6%);
    --color-single--text: hsl(223, 100%, 15%);

    --color-budding: hsl(41, 100%, 50%);
    --color-budding--default-bg: hsl(41, 100%, 60%);
    --color-budding--bg: hsl(41, 100%, 85%);
    --color-budding--text: hsl(41, 100%, 15%);

    --color-clumping: hsl(289, 53.9%, 32.4%);
    --color-clumping--default-bg: hsl(289, 53.9%, 42.4%);
    --color-clumping--bg: hsl(289, 53.9%, 57.4%);
    --color-clumping--text: hsl(289, 53.9%, 15%);

    --color-dividing: hsl(23, 100%, 49.8%);
    --color-dividing--default-bg: hsl(23, 100%, 59.8%);
    --color-dividing--bg: hsl(23, 100%, 74.8%);
    --color-dividing--text: hsl(23, 100%, 15%);

    --color-mean-and-ci-graph: rgb(00, 64, 50);
    --color-mean-and-ci-graph-lighter: hsl(167, 100%, 32.5%);

    --color-for-plot-1: rgb(100, 143, 255);
    --color-for-plot-2: rgb(120, 94, 240);
    --color-for-plot-3: rgb(110, 38, 127);
    --color-for-plot-4: rgb(254, 97, 0);
    --color-for-plot-5: rgb(255, 176, 0);
}


.toggle-switch-alivestarter {
    --toggle-handle__diameter: 1.5em;
    --toggle-handle__offset: 0.25em;
    --toggle-handle__border-for-windows: 0.1em;
    --toggle-slider__default-bg: var(--color-single--default-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: var(--color-single--bg);
    padding: 0.5em;
    border-radius: 100vw;
}

.toggle-switch-alivestarter__slider {
    border-radius: 100vw;
    cursor: pointer;
    position: relative;
    transition: 250ms;
    border: 0.1em solid rgba(0, 0, 0, 0.2);
    width: calc(var(--toggle-handle__diameter) * 2 + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    height: calc(var(--toggle-handle__diameter) + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    background-color: var(--toggle-slider__default-bg);
}

.toggle-switch-alivestarter__slider::before {
    content: "";
    width: var(--toggle-handle__diameter);
    height: var(--toggle-handle__diameter);
    border-radius: 50%;
    transition: inherit;
    position: absolute;
    left: var(--toggle-handle__offset);
    top: 50%;
    transform: translate(0, -50%);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.toggle-switch-alivestarter__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.toggle-switch-alivestarter__input:focus + .toggle-switch-alivestarter__slider {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
    outline-offset: 3px;
}

.toggle-switch-alivestarter__input:focus:not(:focus-visible) + .toggle-switch-alivestarter__slider {
    outline: 0;
}

.toggle-switch-alivestarter__input:checked + .toggle-switch-alivestarter__slider {
    background-color: var(--color-single);
}

.toggle-switch-alivestarter__input:checked + .toggle-switch-alivestarter__slider::before {
    transform: translate(100%, -50%);
}

.toggle-switch-alivestarter__label {
    margin: 0 0.5em;
    color: #660f00;
    font-size: 1.2em;
}

@media (prefers-reduced-motion: reduce) {
    .toggle-switch-alivestarter {
        transition-duration: 0ms;
    }
}


.toggle-switch-aliveelongated {
    --toggle-handle__diameter: 1.5em;
    --toggle-handle__offset: 0.25em;
    --toggle-handle__border-for-windows: 0.1em;
    --toggle-slider__default-bg: var(--color-dividing--default-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: var(--color-dividing--bg);
    padding: 0.5em;
    border-radius: 100vw;
}

.toggle-switch-aliveelongated__slider {
    border-radius: 100vw;
    cursor: pointer;
    position: relative;
    transition: 250ms;
    border: 0.1em solid rgba(0, 0, 0, 0.2);
    width: calc(var(--toggle-handle__diameter) * 2 + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    height: calc(var(--toggle-handle__diameter) + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    background-color: var(--toggle-slider__default-bg);
}

.toggle-switch-aliveelongated__slider::before {
    content: "";
    width: var(--toggle-handle__diameter);
    height: var(--toggle-handle__diameter);
    border-radius: 50%;
    transition: inherit;
    position: absolute;
    left: var(--toggle-handle__offset);
    top: 50%;
    transform: translate(0, -50%);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.toggle-switch-aliveelongated__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.toggle-switch-aliveelongated__input:focus + .toggle-switch-aliveelongated__slider {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
    outline-offset: 3px;
}

.toggle-switch-aliveelongated__input:focus:not(:focus-visible) + .toggle-switch-aliveelongated__slider {
    outline: 0;
}

.toggle-switch-aliveelongated__input:checked + .toggle-switch-aliveelongated__slider {
    background-color: var(--color-dividing);
}

.toggle-switch-aliveelongated__input:checked + .toggle-switch-aliveelongated__slider::before {
    transform: translate(100%, -50%);
}

.toggle-switch-aliveelongated__label {
    margin: 0 0.5em;
    color: #660f00;
    font-size: 1.2em;
}

@media (prefers-reduced-motion: reduce) {
    .toggle-switch-aliveelongated {
        transition-duration: 0ms;
    }
}


.toggle-switch-deadelongated {
    --toggle-handle__diameter: 1.5em;
    --toggle-handle__offset: 0.25em;
    --toggle-handle__border-for-windows: 0.1em;
    --toggle-slider__default-bg: var(--color-budding--default-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: var(--color-budding--bg);
    padding: 0.5em;
    border-radius: 100vw;
}

.toggle-switch-deadelongated__slider {
    border-radius: 100vw;
    cursor: pointer;
    position: relative;
    transition: 250ms;
    border: 0.1em solid rgba(0, 0, 0, 0.2);
    width: calc(var(--toggle-handle__diameter) * 2 + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    height: calc(var(--toggle-handle__diameter) + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    background-color: var(--toggle-slider__default-bg);
}

.toggle-switch-deadelongated__slider::before {
    content: "";
    width: var(--toggle-handle__diameter);
    height: var(--toggle-handle__diameter);
    border-radius: 50%;
    transition: inherit;
    position: absolute;
    left: var(--toggle-handle__offset);
    top: 50%;
    transform: translate(0, -50%);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.toggle-switch-deadelongated__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.toggle-switch-deadelongated__input:focus + .toggle-switch-deadelongated__slider {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
    outline-offset: 3px;
}

.toggle-switch-deadelongated__input:focus:not(:focus-visible) + .toggle-switch-deadelongated__slider {
    outline: 0;
}

.toggle-switch-deadelongated__input:checked + .toggle-switch-deadelongated__slider {
    background-color: var(--color-budding);
}

.toggle-switch-deadelongated__input:checked + .toggle-switch-deadelongated__slider::before {
    transform: translate(100%, -50%);
}

.toggle-switch-deadelongated__label {
    margin: 0 0.5em;
    color: #660f00;
    font-size: 1.2em;
}

@media (prefers-reduced-motion: reduce) {
    .toggle-switch-deadelongated {
        transition-duration: 0ms;
    }
}


.toggle-switch-deadstarter {
    --toggle-handle__diameter: 1.5em;
    --toggle-handle__offset: 0.25em;
    --toggle-handle__border-for-windows: 0.1em;
    --toggle-slider__default-bg: var(--color-clumping--default-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: var(--color-clumping--bg);
    padding: 0.5em;
    border-radius: 100vw;
}

.toggle-switch-deadstarter__slider {
    border-radius: 100vw;
    cursor: pointer;
    position: relative;
    transition: 250ms;
    border: 0.1em solid rgba(0, 0, 0, 0.2);
    width: calc(var(--toggle-handle__diameter) * 2 + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    height: calc(var(--toggle-handle__diameter) + var(--toggle-handle__offset) * 2 + var(--toggle-handle__border-for-windows) * 2);
    background-color: var(--toggle-slider__default-bg);
}

.toggle-switch-deadstarter__slider::before {
    content: "";
    width: var(--toggle-handle__diameter);
    height: var(--toggle-handle__diameter);
    border-radius: 50%;
    transition: inherit;
    position: absolute;
    left: var(--toggle-handle__offset);
    top: 50%;
    transform: translate(0, -50%);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.toggle-switch-deadstarter__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.toggle-switch-deadstarter__input:focus + .toggle-switch-deadstarter__slider {
    outline: 1px auto Highlight;
    outline: 1px auto -webkit-focus-ring-color;
    outline-offset: 3px;
}

.toggle-switch-deadstarter__input:focus:not(:focus-visible) + .toggle-switch-deadstarter__slider {
    outline: 0;
}

.toggle-switch-deadstarter__input:checked + .toggle-switch-deadstarter__slider {
    background-color: var(--color-clumping);
}

.toggle-switch-deadstarter__input:checked + .toggle-switch-deadstarter__slider::before {
    transform: translate(100%, -50%);
}

.toggle-switch-deadstarter__label {
    margin: 0 0.5em;
    color: #660f00;
    font-size: 1.2em;
}

@media (prefers-reduced-motion: reduce) {
    .toggle-switch-deadstarter {
        transition-duration: 0ms;
    }
}

.button-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.chart-button {
    --background-color: lightgray;
    --text-color: black;
    border-radius: 4px;
    border: none;
    padding: 0.75em;
    border: 1px solid;
    background-color: var(--background-color);
    color: var(--text-color);
    border-color: var(--text-color);
}

.chart-button--alivestarter {
    --background-color: var(--color-single--bg);
    --text-color: var(--color-single--text);
}

.chart-button--aliveelongated {
    --background-color: var(--color-dividing--bg);
    --text-color: var(--color-dividing--text);
}

.chart-button--deadstarter {
    --background-color: var(--color-clumping--bg);
    --text-color: var(--color-clumping--text);
}

.chart-button--deadelongated {
    --background-color: var(--color-budding--bg);
    --text-color: var(--color-budding--text);
}

.plot-handle__wrapper.plot-handle__wrapper > * {
    margin-right: 1em;
}

.plot-handle__label-parent.plot-handle__label-parent {
    min-width: 110px;
}

.dashboard-show__label-plot-name.dashboard-show__label-plot-name {
    min-width: 200px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


.dashboard__plot-list, .plot-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.plot-list {
    flex-direction: column;
}

.dashboard-item {
    width: 1000px;
}

.dashboard-item, .plot-item {
    max-width: 100%;
    min-width: 800px;
    flex-grow: 1;
    flex-shrink: 1;
}


 body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}



.experiments__download-icon {
    --color: #2196F3;
    --bg-color:white;
    --border-color: #2196F3;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    color: var(--color);
    background-color: var(--bg-color);
    padding: .05em;
}

.experiments__download-icon.already_downloaded_icon{
    --bg-color: #2196F3;
    --color:white;
}